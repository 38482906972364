<template>
  <div>
    <div ref="slider" class="homepage-slider">
      <SsrCarousel
        ref="list-slider"
        v-model="carousel1Index"
        :slides-per-page="3"
        :paginate-by-slide="true"
        :responsive="[
          {
            minWidth: 1024,
            slidesPerPage: 6,
          },
        ]"
        class="list-slider"
        loop
        :gutter="0"
        center
        @change="handleCarousel1Change"
      >
        <ul v-for="(item, itemIndex) in slides" :key="`homepage_slider_${itemIndex}`">
          <li
            class="list-item"
            :class="{ '--active': itemIndex === active }"
            @click="handleCarousel1Change({ index: itemIndex })"
          >
            <components :is="listSlidesIcons[itemIndex]" :key="item.content" />
            <p class="content">
              {{ item.content }}

              <span
                v-if="itemIndex === active && slides.length > 1"
                class="loader"
                :class="{ '--reset-animation': resetAnimation }"
              >
              </span>
            </p>
          </li>
        </ul>
      </SsrCarousel>

      <div class="image-container">
        <SsrCarousel
          ref="images-slider"
          v-model="carousel2Index"
          class="images-slider"
          loop
          :autoplay-delay="autoplayDelaySeconds"
          :slides-per-page="1"
          @change="handleCarousel2Change"
        >
          <AppImage
            v-for="(image, imageIndex) in slides"
            :key="`homepage_slider_image_${imageIndex}`"
            :image="image.image.srcSet"
            :blurred-preview="image.image.imageBlur"
          />
        </SsrCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import SsrCarousel from 'vue-ssr-carousel'
import { isElementInViewport } from '@/utils/is-element-in-viewport'
import AppImage from '@/components/common/AppImage'
import 'vue-ssr-carousel/index.css'

const DEFAULT_AUTOPLAY_DELAY = 6

export default {
  name: 'HomepageSlider',
  components: { AppImage, SsrCarousel },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    slides: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      carousel1Index: 0, // Initial index for carousel 1
      carousel2Index: 0, // Initial index for carousel 2,
      autoplayDelaySeconds: DEFAULT_AUTOPLAY_DELAY,
      listSlidesIcons: [
        () => import(`@/components/icons/Box3DIcon.vue`),
        () => import(`@/components/icons/AreaIcon.vue`),
        () => import(`@/components/icons/FurnituresIcon.vue`),
        () => import(`@/components/icons/DayNightIcon.vue`),
        () => import(`@/components/icons/BoxEyeIcon.vue`),
        () => import(`@/components/icons/PhotosIcon.vue`),
      ],
      listSlides: [],
      intervalValue: 6000,
      intervalID: null,
      resetAnimation: false,
      isInViewport: false,
      sliderRef: null,
      listSliderRef: null,
      imagesSliderRef: null,
    }
  },
  computed: {
    active() {
      return this.carousel2Index
    },
  },
  watch: {
    isInViewport(v) {
      v ? this.runCarousel() : this.stopCarousel()
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    this.$nextTick(() => {
      this.sliderRef = this.$refs?.slider
      this.listSliderRef = this.$refs?.['list-slider']
      this.imagesSliderRef = this.$refs?.['images-slider']
      if (this.sliderRef) {
        this.isInViewport = isElementInViewport(this.sliderRef)
      }

      if (this.sliderRef) window.addEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    runAnimation() {
      this.resetAnimation = false
    },
    stopAnimation() {
      this.resetAnimation = true
    },
    stopAutoplay() {
      this.imagesSliderRef.autoplayStop()
    },
    runAutoplay() {
      this.imagesSliderRef.autoplayStart()
    },
    runCarousel() {
      this.stopCarousel()
      this.$nextTick(() => {
        this.runAnimation()
        this.runAutoplay()
      })
    },
    stopCarousel() {
      this.stopAnimation()
      this.stopAutoplay()
    },
    handleCarousel1Change(page) {
      if (page.index >= 0) this.carousel2Index = page.index
    },
    handleCarousel2Change(page) {
      if (page.index >= 0) this.carousel1Index = page.index
      this.runCarousel()
    },
    handleScroll() {
      this.isInViewport = isElementInViewport(this.sliderRef)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/homepage/HomepageSlider';
</style>
