<template>
  <div v-if="!withoutLazyLoad" class="image-container">
    <img
      ref="image"
      :key="image"
      v-lazy-load :data-srcset="image"
      :sizes="sizes"
      class="image"
      :alt="alt"
      :role="alt ? '' : 'presentation'"
      draggable="false"
      :width="1920"
      :height="1080"
      @load="onImageLoad"
    />
    <img
      :key="blurredPreview"
      class="blurred-placeholder"
      v-lazy-load :data-src="`data:image/png;base64, ${blurredPreview}`"
      alt=""
      role="presentation"
      :width="1920"
      :height="1080"
      data-not-lazy
    />
  </div>
  <div v-else class="image-container">
    <img
      v-lazy-load :data-srcset="image"
      :sizes="sizes"
      class="image"
      :width="1920"
      :height="1080"
      :alt="alt"
      :role="alt ? '' : 'presentation'"
      draggable="false"
      data-not-lazy
    />
  </div>
</template>

<script>
import AOS from 'aos'

export default {
  name: 'AppImage',
  props: {
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'photo',
    },
    sizes: {
      type: String,
      default: '(max-width: 1024px) 100vw, 1024px',
    },
    blurredPreview: {
      type: String,
      default: '',
    },
    withoutLazyLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageLoaded: false,
      lazyImageRef: null,
    }
  },
  mounted() {
    window.addEventListener('resize', this.onImageLoad)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onImageLoad)
  },
  methods: {
    onImageLoad() {
      // this.setImageSize()
      this.imageLoaded = true
      AOS.refresh()
      this.$emit('image-loaded')
    },
    setImageSize() {
      const image = this.$refs.image
      if (!image) return
      image.style.width = `${image.clientWidth}px`
      image.style.height = `${(image.clientWidth * 9) / 16}px`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/AppImage';
</style>
